import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  config: null,
  ltiVersions: null,
  error: null,
  loading: null
};

export const getConfig = createAsyncThunk("api/get-config", async ({ ltiVersion }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/lti/${ltiVersion}/config`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error?.message : data?.message);
  }
});

//create config

export const createConfig = createAsyncThunk(
  "api/create-config",
  async ({ values, ltiVersion }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.post(`/lti/${ltiVersion}/config`, values);

      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getConfig({ ltiVersion }));
        return data;
      }
      return null;
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error?.message : data?.message);
    }
  }
);

//get lti versions

export const getLtiVersions = createAsyncThunk("api/get-versions", async () => {
  let data;
  try {
    const response = await axiosInstance.get(`/lti/versions`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error?.message : data?.message);
  }
});

export const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    resetoffersSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [getConfig.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getConfig.fulfilled]: (state, action) => {
      state.config = action.payload;
      state.loading = false;
    },
    [getConfig.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [createConfig.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createConfig.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createConfig.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    //get lti versions
    [getLtiVersions.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getLtiVersions.fulfilled]: (state, action) => {
      state.loading = false;
      state.ltiVersions = action.payload;
    },
    [getLtiVersions.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    }
  }
});

export const { resetoffersSliceData } = configSlice.actions;

export default configSlice.reducer;
