import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logout } from "../../data/slices/authSlice";
import powerIcon from "../../assets/icons/power.svg";
import refreshIcon from "../../assets/icons/refresh.svg";
import fi_down from "../../assets/icons/fi_down.svg";
import { AbilityContext, Can } from "../../context/permissions/Can";
import { ReactComponent as ProfileIcon } from "./../../assets/icons/userProfile.svg";
import { Avatar, Button, Dropdown, Space, notification } from "antd";
import Countdown from "react-countdown";
import { store } from "../../data";
import { openModal } from "../../data/slices/modals";
import FeatureProvider from "../../providers/FeaturesProvider/FeatureProvider";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { getNavigateRoute } from "../../utils/getNavigateRoute";
import { setHeaderTitle } from "../../data/slices/tabsSlice";
import { UserOutlined } from "@ant-design/icons";

const NotificationDescription = ({ duration, nbSessions }) => {
  const { t } = useTranslation("index");
  const renderer = ({ seconds }) => {
    return <span>{seconds}</span>;
  };
  const sessionMessage =
    nbSessions === 1 ? " an active session " : ` ${nbSessions} active sessions `;

  return (
    <div className="notification-description">
      <p>
        {t("home.NotificationDescription-1")} {sessionMessage}.{" "}
        {t("home.NotificationDescription-2")}
        <Countdown
          date={Date.now() + duration * 1000}
          renderer={renderer}
          onComplete={() => {
            store.dispatch(logout());
            notification.destroy();
          }}
        />
        {t("home.NotificationDescription-3")}
      </p>
    </div>
  );
};

const DropDownHeader = ({ isAdmin }) => {
  const { t } = useTranslation("index");
  const { user } = useSelector((state) => state.auth);
  const { desktopSessions, sessions } = useSelector((state) => state.sessions);

  const { currentPlan } = useSelector((state) => state.currentPlan);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const logoutHandler = () => {
    const openSessions = desktopSessions?.data?.length;
    if (openSessions) {
      const duration = 20;
      notification.info({
        message: <p className="notification-title">{t("MenuHeader-1")}</p>,
        description: <NotificationDescription duration={duration} nbSessions={openSessions} />,
        placement: "top",
        btn: (
          <Space>
            <Button size="middle" onClick={() => notification.destroy()}>
              {t("home.MenuHeader-2")}
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                notification.destroy();
                dispatch(logout());
                searchParams.delete("type");
                searchParams.delete("callbackURL");
                searchParams.delete("redirectURL");
                searchParams.delete("code");
                setSearchParams(searchParams);
              }}
            >
              {t("home.MenuHeader-3")}
            </Button>
          </Space>
        ),
        duration
      });
    } else {
      dispatch(logout());
      searchParams.delete("type");
      searchParams.delete("callbackURL");
      searchParams.delete("redirectURL");
      setSearchParams(searchParams);
    }
  };

  const ability = useContext(AbilityContext);

  const handleSwitch = () => {
    navigate(getNavigateRoute(ability)?.link);
    dispatch(setHeaderTitle(t(getNavigateRoute(ability)?.label)));
  };

  const items = [
    {
      label: (
        <span
          className="drop_down_item"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <ProfileIcon />
          {t("home.profile")}
        </span>
      ),
      key: "0"
    },
    {
      label: (
        <Can I="switch" a="admin">
          {isAdmin ? (
            <span
              className="drop_down_item"
              onClick={() => {
                navigate("/");
              }}
              selected={false}
            >
              <img src={refreshIcon} alt="" />
              {t("home.home")}
            </span>
          ) : (
            <span className="drop_down_item" onClick={handleSwitch} selected={false}>
              <img src={refreshIcon} alt="" />
              {t("home.dashboard")}
            </span>
          )}
        </Can>
      ),
      key: "1"
    },
    {
      label: (
        <FeatureProvider status={currentPlan?.snapshotFeature?.isOn} type="hide">
          <span
            className="drop_down_item"
            onClick={() => {
              dispatch(openModal("snapshot-list-modal"));
            }}
          >
            <ProfileIcon />
            {t("home.snapshot")}
          </span>
        </FeatureProvider>
      ),
      key: "2"
    },
    {
      type: "divider"
    },
    {
      label: (
        <span className="drop_down_item" onClick={logoutHandler}>
          <img src={powerIcon} alt="" />
          {t("home.sign-out")}
        </span>
      ),
      key: "3"
    }
  ];

  return (
    <div className="menu">
      <Dropdown
        menu={{
          items
        }}
        trigger={["click"]}
        overlayClassName="menu_main"
      >
        <div className="avatar" onClick={(e) => e.preventDefault()}>
          <Avatar
            icon={<UserOutlined />}
            src={user?.profilePicture ? user?.profilePicture : null}
            className="profile__avatar"
            id="basic-button"
            size={40}
          />
          <img src={fi_down} alt="" />
        </div>
      </Dropdown>
    </div>
  );
};

export default DropDownHeader;
