import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { useLTICheck } from "../../hooks/useLTICheck";
import GuestGuard from "../../components/GuestGuard";

const GuestLayout = () => {
  useLTICheck();

  return (
    <GuestGuard>
      <Suspense>
        <div className="guest_layout">
          <Outlet />
        </div>
      </Suspense>
    </GuestGuard>
  );
};

export default GuestLayout;
