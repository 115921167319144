import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import AuthGuard from "../../components/AuthGuard";
import Backdrop from "../../components/Backdrop/Backdrop";
import Header from "../../components/Header";
import { useLTICheck } from "../../hooks/useLTICheck";
// import SettingNavigation from "../../features/Settings/components/SettingNavigation/SettingNavigation";

function SettingLayout() {
  useLTICheck();
  return (
    <AuthGuard>
      <div className="profile_layout">
        <div className="profile_layout__container">
          <Header />
          <Suspense>
            <div className="flex_nav">
              {/* <SettingNavigation /> */}
              <div className="profile_pages">
                <Outlet />
              </div>
            </div>
          </Suspense>
        </div>
        <Backdrop />
      </div>
    </AuthGuard>
  );
}

export default SettingLayout;
