import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ModalsProvider from "./components/ModalsProvider";
import MainLayout from "./layouts/MainLayout";
// import Progress from "./components/Progress/Progress";
import NotFoundView from "./components/NotFound";
import NoPermissionView from "./components/NoPermissionView";
import GuestLayout from "./layouts/GuestLayout";
import AdminLayout from "./layouts/AdminLayout";

import { lazy, useEffect } from "react";
import { Suspense } from "react";
import DrawersProvider from "./components/DrawersProvider";
import GetPermissions from "./context/permissions/GetPermissions";
import { AbilityContext } from "./context/permissions/Can";
import { useSelector } from "react-redux";

import SettingLayout from "./layouts/SettingLayout";
import LazyLoad from "./components/LazyLoad/LazyLoad";
import { Profile } from "./features/Settings";

import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import QuotaManagment from "./features/QuotaManagment";
import { Config } from "./config";
import { useTranslation } from "react-i18next";

import ReactGA from "react-ga";
import LtiAuthentication from "./features/LtiAuthentication";
import LTiLayout from "./layouts/LtiLayout/LTiLayout";

ReactGA.initialize(Config?.REACT_APP_ANALYTICS);

const RoutesProvider = () => {
  if (process.env.NODE_ENV !== "development") console.log = function no_console() {};

  const { i18n } = useTranslation("translation");

  document.body.dir = i18n?.dir();

  const LoginView = lazy(() => import("./views/Login"));
  const RegisterView = lazy(() => import("./views/Register"));
  const InvitRegisterView = lazy(() => import("./views/InvitRegister"));

  const ResetPasswordView = lazy(() => import("./views/ResetPassword"));
  const ForgetPasswordView = lazy(() => import("./views/ForgetPassword"));
  const Desktops = lazy(() => import("./views/Desktops"));
  // const Profile = lazy(() => import("./views/Profile"));
  const Security = lazy(() => import("./views/Security"));
  const UserGroup = lazy(() => import("./views/UserGroup"));
  const ImageGroup = lazy(() => import("./views/ImageGroup"));
  const VNCViewer = lazy(() => import("./views/VNCViewer"));
  const RDPViewer = lazy(() => import("./views/RDPViewer"));
  const BillingPageView = lazy(() => import("./views/Billing"));
  const LdapConfig = lazy(() => import("./views/LdapConfig"));
  const LdapView = lazy(() => import("./views/Ldap"));
  const Offers = lazy(() => import("./views/Offers"));
  const Dashboard = lazy(() => import("./views/Dashboard"));
  const Users = lazy(() => import("./views/Users"));
  const NetworkPolicy = lazy(() => import("./views/NetworkPolicy"));
  const LtiConfig = lazy(() => import("./views/LtiConfig"));

  const OidcVerify = lazy(() => import("./views/OidcVerify"));

  const auth = useSelector((state) => state?.auth);
  const listOfPermissions = GetPermissions(auth?.user?.roles, false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <FlagsmithProvider
      options={{
        environmentID: Config?.REACT_APP_FLAGSMITH_KEY,
        api: Config?.REACT_APP_FLAGSMITH_API
      }}
      flagsmith={flagsmith}
    >
      <AbilityContext.Provider value={listOfPermissions}>
        <BrowserRouter>
          <Suspense fallback={<LazyLoad />}>
            <Routes>
              {/* quth google */}

              <Route path="/login/oidc/verify" element={<OidcVerify />} />
              <Route path="/signup/oidc/verify" element={<OidcVerify />} />

              {/* Guests Routes */}
              <Route path="/auth" element={<GuestLayout />}>
                <Route index element={<Navigate to="login" />} />
                <Route path="login" element={<LoginView />} />
                <Route path="login/ldap" element={<LdapView />} />
                <Route path="register" element={<RegisterView />} />
                <Route path="accept-invite" element={<InvitRegisterView />} />
                <Route path="reset-password" element={<ResetPasswordView />} />
                <Route path="forget-password" element={<ForgetPasswordView />} />
                <Route path="oidc/verify" element={<OidcVerify />} />
                <Route path="lti/desktop/:desktop_id" element={<LtiAuthentication />} />

                {/* <Route path="oidc/verify/login" element={<OidcVerify />} />
                <Route path="oidc/verify/signup" element={<OidcVerify />} /> */}
              </Route>
              {/* Users Routes */}
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Desktops />} />
                <Route path="control/:session" element={<VNCViewer />} />
                {/* <Route path="lti/control/:session" element={<LtiDesktopLauncher />} /> */}
                <Route path="control/rdp" element={<RDPViewer />} />
                <Route path="success" element={<p>payment successfully</p>} />
                <Route path="offers" element={<Offers />} />

                {/* <Route path="/profile" element={<Profile />} /> */}
              </Route>
              {/* LTI Control */}
              <Route path="/lti" element={<LTiLayout />}>
                <Route path="control/:session" element={<VNCViewer />} />
              </Route>
              <Route path="/settings" element={<SettingLayout />}>
                <Route index element={<Navigate to="/settings/profile" />} />
                <Route path="profile" element={<Profile />} />
                <Route path="teams" element={<p>teams</p>} />
                <Route path="notification" element={<p>notification</p>} />
                <Route path="billing" element={<p>billing</p>} />
              </Route>
              {/* Admin Routes */}
              <Route path="/admin" element={<AdminLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="users" element={<Users />} />
                <Route path="user-groups" element={<UserGroup />} />
                <Route path="images" element={<ImageGroup />} />
                <Route path="billing" element={<BillingPageView />} />
                <Route path="ldap-config" element={<LdapConfig />} />
                <Route path="plans" element={<Offers />} />
                <Route path="network-policy" element={<NetworkPolicy />} />
                <Route path="quota-managment" element={<QuotaManagment />} />
                <Route path="quota-managment/:groupId" element={<QuotaManagment />} />
                <Route path="lti-config" element={<LtiConfig />} />
              </Route>
              {/* Uncreated Routes */}

              <Route path="403" element={<NoPermissionView />} />

              <Route path="*" element={<NotFoundView />} />
            </Routes>
          </Suspense>
          <ModalsProvider />
          <DrawersProvider />
        </BrowserRouter>
      </AbilityContext.Provider>
    </FlagsmithProvider>
  );
};

export default RoutesProvider;
