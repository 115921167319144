import React, { useEffect } from "react";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initialise, login, setSession } from "../../data/slices/authSlice";
import { getAllTemplates } from "../../data/slices/templates";
import { new_session, newSession } from "../../data/slices/sessions";
import { message } from "antd";
import axiosInstance from "../../utils/axios";
import ltiAxios from "../../utils/ltiAxios";

function LtiAuthentication() {
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessToken = searchParams.get("accessToken");
  const cpu = searchParams.get("cpu");
  const gpu = searchParams.get("gpu");
  const ram = searchParams.get("ram");
  const storage = searchParams.get("storage");

  const { isAuthenticated } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);

  const createSession = (desktop) => {
    dispatch(
      newSession({
        namespace: "default",
        template: desktop.name,
        sessionConfig: {
          cpu: cpu ? cpu : desktop?.skuConfig?.cpu?.defaultValue,
          ram: ram ? ram : desktop?.skuConfig?.ram?.defaultValue,
          storage: storage ? storage : desktop?.skuConfig?.storage?.defaultValue,
          // gpu: gpu ? gpu : desktop?.skuConfig?.gpu?.defaultValue ,
          gpu: "0",
          shouldPersist: false
        },
        shouldPersist: false,
        redirectURL: ""
      })
    )
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(new_session(res));
          navigate(`/lti/control/${res.name}`, {
            state: res
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
        // navigate("/admin/plans");
      });
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        if (accessToken) {
          setSession(accessToken);
          const response = await ltiAxios.get("profile/whoami");
          const user = response.data;
          axiosInstance.defaults.headers["X-User-ID"] = user?.username;
          dispatch(initialise({ isAuthenticated: true, user }));
          dispatch(getAllTemplates({ query: "" }))
            .unwrap()
            .then((res) => {
              const targetDesktop = res?.templates?.find(
                (desktop) => desktop?.name === params?.desktop_id
              );
              if (targetDesktop) {
                createSession(targetDesktop);
              } else {
                message.error("Desktop not found");
                setLoading(false);
              }
            });
        } else {
          dispatch(initialise({ isAuthenticated: false, user: null }));
          window.localStorage.removeItem("gomydesk_token");
        }
      } catch (error) {
        message.error("Token is invalid or expired");
        dispatch(initialise({ isAuthenticated: false, user: null }));
        window.localStorage.removeItem("gomydesk_token");
      }
    }

    if (isAuthenticated) {
      dispatch(getAllTemplates({ query: "" }))
        .unwrap()
        .then((res) => {
          const targetDesktop = res?.templates?.find(
            (desktop) => desktop?.name === params?.desktop_id
          );
          if (targetDesktop) {
            createSession(targetDesktop);
          } else {
            message.error("Desktop not found");
            setLoading(false);
          }
        });
    } else {
      fetchUser();
    }
  }, []);

  return (
    <div className="lti-auth">
      <div className="lti-loading">{loading ? <LoaderComponent /> : "Desktop Not Found"}</div>
    </div>
  );
}

export default LtiAuthentication;
