import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getConfig } from "../data/slices/ltiConfig";

export const useLTICheck = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.ltiConfig);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getConfig({ ltiVersion: "1.3" }));
  }, [dispatch]);

  useEffect(() => {
    // Check if user is from LTI and not an owner
    if (config && user) {
      const isOwner = user.roles?.some((role) => role.name === "gomydesk-org-owner");
      if (!isOwner && config?.issuer_url) {
        navigate("/session-deleted");
      }
    }
  }, [config, user, navigate]);
};
