import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import AuthGuard from "../../components/AuthGuard";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Backdrop from "../../components/Backdrop";
import { useLTICheck } from "../../hooks/useLTICheck";

const MainLayout = () => {
  useLTICheck();
  return (
    <AuthGuard>
      <div className="admin_layout">
        <Sidebar />
        <div className="admin_layout__container">
          <Header isAdmin={true} />
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
        <Backdrop />
      </div>
    </AuthGuard>
  );
};

export default MainLayout;
