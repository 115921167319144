export const copyright_flag = "copyright";
export const invite_user_flag = "invite_user";
export const ldap_auth_flag = "ldap";
export const signup_flag = "signup";
export const payment_with_subscription = "payment_with_subscription";
export const google_auth_login_flag = "oauth2_login";
export const google_auth_signup_flag = "oauth2_signup";

//audio flag
export const audio_flag = "audio";
//microphone flag
export const microphone_flag = "microphone";
//file transfer flag
export const file_transfer_flag = "file_transfer";
//stream quality flag
export const stream_quality_flag = "stream_quality";
//full screen flag
export const full_screen_flag = "full_screen";
//clipboard flag
export const clipboard_flag = "clipboard";
//snapshot modal flag
export const snapshot_modal_flag = "snapshot_modal";

// feedback flag
export const feedback_flag = "feedback";
// lti config flag
export const lti_config_flag = "lti_config";
// network policy flag
export const network_policy_flag = "network_policy";
// quota management flag
export const quota_management_flag = "quota_management";
